export const country_region = [
	{ value: "es_ES", lable: "스페인 - 스페인어" },
	{ value: "en_US", lable: "미국 - 영어" },
  { value: "en_AA", lable: "아프리카 - 영어" },
	{ value: "fr_AA", lable: "아프리카 - 프랑스어" },
	{ value: "es_AR", lable: "아르헨티나 - 스페인어" },
	{ value: "en_AU", lable: "호주 - 영어" },
	{ value: "de_AT", lable: "오스트리아 - 독일어" },
	{ value: "be_BY", lable: "벨로루시 - 러시아어" },
	{ value: "nl_BE", lable: "벨기에 - 네덜란드어" },
	{ value: "fr_BE", lable: "룩셈부르크 - 프랑스어" },
	{ value: "es_BO", lable: "볼리비아 - 스페인어" },
	{ value: "pt_BR", lable: "브라질 - 포르투갈어" },
	{ value: "bg_BG", lable: "불가리아 - 불가리아어" },
	{ value: "en_BG", lable: "불가리아 - 영어" },
	{ value: "en_CA", lable: "캐나다 - 영어" },
	{ value: "fr_CA", lable: "캐나다 - 프랑스어" },
	{ value: "en_CB", lable: "카리브 해 - 영어" },
	{ value: "es_NS", lable: "중앙 아메리카 - 스페인어" },
	{ value: "es_CL", lable: "칠레 - 스페인어" },
	{ value: "zh_CN", lable: "중국 - 중국어 간체" },
	{ value: "es_CO", lable: "콜롬비아 - 스페인어" },
	{ value: "hr_HR", lable: "크로아티아 - 크로아티아어" },
	{ value: "cs_CZ", lable: "체코 - 체코어" },
	{ value: "da_DK", lable: "덴마크 - 덴마크어" },
	{ value: "es_EC", lable: "에콰도르 - 스페인어" },
	{ value: "et_EE", lable: "에스토니아 - 에스토니아어" },
	{ value: "fi_FI", lable: "핀란드 - 핀란드어" },
	{ value: "fr_FR", lable: "프랑스 - 프랑스어" },
	{ value: "de_DE", lable: "독일 - 독일어" },
	{ value: "el_GR", lable: "그리스 - 그리스어" },
	{ value: "en_HK", lable: "홍콩 - 영어" },
	{ value: "zh_HK", lable: "홍콩 - 중국어 번체" },
	{ value: "hu_HU", lable: "헝가리 - 헝가리어" },
	{ value: "en_IN", lable: "인도 - 영어" },
	{ value: "en_ID", lable: "인도네시아 - 영어" },
	{ value: "in_ID", lable: "인도네시아  -  바하사" },
	{ value: "en_IE", lable: "아일랜드 - 영어" },
	{ value: "he_IL", lable: "이스라엘 - 히브리어" },
	{ value: "it_IT", lable: "이탈리아 - 이탈리아어" },
	{ value: "ja_JP", lable: "일본 - 일본어" },
	{ value: "ru_KZ", lable: "카자흐스탄 - 러시아어" },
	{ value: "ko_KR", lable: "한국 - 한국어" },
	{ value: "lv_LV", lable: "라트비아 - 라트비아어" },
	{ value: "lt_LT", lable: "리투아니아 - 리투아니아어" },
	{ value: "en_LB", lable: "레바논 - 영어" },
	{ value: "en_MY", lable: "말레이시아 - 영어" },
	{ value: "es_MX", lable: "멕시코 - 스페인어" },
	{ value: "en_ME", lable: "중동 - 영어" },
	{ value: "nl_NL", lable: "네덜란드 - 네덜란드어" },
	{ value: "en_NZ", lable: "뉴질랜드 - 영어" },
	{ value: "no_NO", lable: "노르웨이 - 노르웨이어" },
	{ value: "en_PK", lable: "파키스탄 - 영어" },
	{ value: "es_PY", lable: "파라과이 - 스페인어" },
	{ value: "es_PE", lable: "페루 - 스페인어" },
	{ value: "en_PH", lable: "필리핀 - 영어" },
	{ value: "pl_PL", lable: "폴란드 - 폴란드어" },
	{ value: "pt_PT", lable: "포르투갈 - 포르투갈어" },
	{ value: "es_PR", lable: "푸에르토리코 - 스페인어" },
	{ value: "en_BD", lable: "방글라데시 - 영어" },
	{ value: "en_RO", lable: "루마니아 - 영어" },
	{ value: "ru_RU", lable: "러시아 - 러시아어" },
	{ value: "en_SA", lable: "사우디 아라비아 - 영어" },
	{ value: "sr_YU", lable: "세르비아 - 세르비아어" },
	{ value: "en_SG", lable: "싱가포르 - 영어" },
	{ value: "sk_SK", lable: "슬로바키아 - 슬로바키아어" },
	{ value: "en_SI", lable: "슬로베니아 - 영어" },
	{ value: "en_ZA", lable: "남아프리카 - 영어" },
	{ value: "en_LK", lable: "스리랑카 - 영어" },
	{ value: "sv_SE", lable: "스웨덴 - 스웨덴어" },
	{ value: "fr_CH", lable: "위스 - 프랑스어" },
	{ value: "de_CH", lable: "스위스 - 독일어" },
	{ value: "zh_TW", lable: "대만 - 중국어 번체" },
	{ value: "en_TH", lable: "타이 - 영어" },
	{ value: "th_TH", lable: "태국  -  태국어" },
	{ value: "tr_TR", lable: "터키 - 터키어" },
	{ value: "uk_UA", lable: "우크라이나 - 러시아어" },
	{ value: "en_AE", lable: "아랍 에미리트 연합국 - 영어" },
	{ value: "en_GB", lable: "영국 - 영어" },
	{ value: "es_UY", lable: "우루과이 - 스페인어" },
	{ value: "es_VE", lable: "베네수엘라 - 스페인어" },
	{ value: "en_VN", lable: "베트남 - 영어" },
	{ value: "all", lable: "모든 국가/지역" },
    { value: "en_SK", label: "슬로바키아 - 영어"},
    { value: "en_LT", label: "리투아니아 - 영어"},
    { value: "en_LV", label: "라트비아 - 영어"},
    { value: "en_EE", label: "에스토니아 - 영어"},

]

export const purchaseLocation = [
  { value: "", lable: "하나를 선택하십시오." },
	{ value: "337", lable: "용산 전자상가" },
	{ value: "338", lable: "hp 대리점(디지털 hp)" },
	{ value: "339", lable: "인터넷 쇼핑몰" },
	{ value: "340", lable: "hp 온라인 구매점" },
	{ value: "341", lable: "일반 컴퓨터 소매점" },
	{ value: "000", lable: "기타" },
];

export const primaryUse = [
  { value: "", lable: "선택해 주십시오." },
	{ value: "002", lable: "개인용" },
	{ value: "003", lable: "재택 근무 사업체" },
	{ value: "005", lable: "직원 9명 이하 사업체" },
	{ value: "006", lable: "직원 10명 이상 사업체" },
]

export const salutation = [
	{ value: "", label:"Select One" },
	{ value: "001", label:"Mr." },
	{ value: "002", label:"Mrs." },
	{ value: "005", label:"Miss" },
]

export const occupation = [
  { value: "", lable: "선택해 주십시오." },
	{ value: "11", lable: "관리직(마케팅,광고,이벤트)" },
	{ value: "13", lable: "경영,회계직" },
	{ value: "15", lable: "컴퓨터설계 및 컴퓨터전문직" },
	{ value: "17", lable: "건축 및 토목설계" },
	{ value: "19", lable: "과학자,연구원" },
	{ value: "21", lable: "사회복지업" },
	{ value: "23", lable: "법률직" },
	{ value: "25", lable: "교육직" },
	{ value: "27", lable: "예술관련 (미술,디자인,연예,스포츠)" },
	{ value: "29", lable: "의사" },
	{ value: "31", lable: "간호사" },
	{ value: "33", lable: "보안 및 경호직" },
	{ value: "35", lable: "숙박 및 음식업" },
	{ value: "37", lable: "건물 및 환경미화, 용역" },
	{ value: "39", lable: "개인서비스직 (보육,여행가이드 등)" },
	{ value: "41", lable: "영업직" },
	{ value: "43", lable: "사무직" },
	{ value: "45", lable: "농축업,임업,어업" },
	{ value: "47", lable: "건설업(노동)" },
	{ value: "503", lable: "공무원" },
	{ value: "504", lable: "군인" },
	{ value: "51", lable: "제조업" },
	{ value: "995", lable: "기타" },
];

export const jobResponsibility = [
  { value: "", lable: "선택해 주십시오" },
	{ value: "031", lable: "간호사" },
	{ value: "033", lable: "보안 및 경호직" },
	{ value: "035", lable: "숙박 및 음식업" },
	{ value: "037", lable: "건물 및 환경미화,용역" },
	{ value: "041", lable: "영업직" },
	{ value: "043", lable: "사무직" },
	{ value: "045", lable: "농축업,임업,어업" },
	{ value: "047", lable: "건설업" },
	{ value: "051", lable: "제조업" },
	{ value: "053", lable: "운송업" },
	{ value: "000", lable: "기타" },
]
