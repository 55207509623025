export const country_region = [
	{ value: "es_ES", label:"Spanyolország - spanyol" },
	{ value: "en_US", label:"Egyesült Államok - angol" },
    { value: "es_AR", label:"Argentína - spanyol" },
	{ value: "en_AW", label:"Aruba - angol" },
	{ value: "en_AU", label:"Ausztrália - angol" },
	{ value: "de_AT", label:"Ausztria - német" },
	{ value: "en_BS", label:"Bahama-szigetek - angol" },
	{ value: "en_BD", label:"Banglades - angol" },
	{ value: "en_BB", label:"Barbados - angol" },
	{ value: "nl_BE", label:"Belgium - holland" },
	{ value: "fr_BE", label:"Belgium - francia" },
	{ value: "es_BO", label:"Bolívia - spanyol" },
	{ value: "pt_BR", label:"Brazília - portugál" },
	{ value: "en_BG", label:"Bulgária - angol" },
	{ value: "en_CA", label:"Kanada - angol" },
	{ value: "fr_CA", label:"Kanada - Francia" },
	{ value: "es_CL", label:"Chile - spanyol" },
	{ value: "zh_CN", label:"Kína - egyszerűsített kínai" },
	{ value: "es_CO", label:"Kolumbia - spanyol" },
	{ value: "es_CR", label:"Costa Rica - spanyol" },
	{ value: "cs_CZ", label:"Csehország - cseh" },
	{ value: "da_DK", label:"Dánia - dán" },
	{ value: "es_DO", label:"Dominikai Köztársaság - spanyol" },
	{ value: "es_EC", label:"Ecuador - spanyol" },
	{ value: "en_EG", label:"Egyiptom - angol" },
	{ value: "es_SV", label:"El Salvador - spanyol" },
	{ value: "fi_FI", label:"Finnország - finn" },
	{ value: "fr_FR", label:"Franciaország - francia" },
	{ value: "de_DE", label:"Németország - német" },
	{ value: "el_GR", label:"Görögország - görög" },
	{ value: "en_GD", label:"Grenada - angol" },
	{ value: "es_GT", label:"Guatemala - spanyol" },
	{ value: "es_HN", label:"Honduras - spanyol" },
	{ value: "en_HK", label:"Hongkong - angol" },
	{ value: "zh_HK", label:"Hongkong - hagyományos kínai" },
	{ value: "hu_HU", label:"Magyarország - magyar" },
	{ value: "en_IN", label:"India - angol" },
	{ value: "en_ID", label:"Indonézia - angol" },
	{ value: "in_ID", label:"Indonézia - Indonéz" },
	{ value: "en_IE", label:"Írország - angol" },
	{ value: "en_IL", label:"Izrael - angol" },
	{ value: "it_IT", label:"Olaszország - olasz" },
	{ value: "en_JM", label:"Jamaica - angol" },
	{ value: "ja_JP", label:"Japán - japán" },
	{ value: "ko_KR", label:"Korea - koreai" },
	{ value: "en_KW", label:"Kuvait - angol" },
	{ value: "en_LB", label:"Libanon - angol" },
	{ value: "fr_LU", label:"Luxemburg - francia" },
	{ value: "en_MY", label:"Malajzia - angol" },
	{ value: "es_MX", label:"Mexikó - spanyol" },
	{ value: "en_MA", label:"Marokkó - angol" },
	{ value: "nl_NL", label:"Hollandia - holland" },
	{ value: "en_NZ", label:"Új-Zéland - angol" },
	{ value: "es_NI", label:"Nicaragua - spanyol" },
	{ value: "no_NO", label:"Norvégia - norvég" },
	{ value: "en_PK", label:"Pakisztán - angol" },
	{ value: "es_PA", label:"Panama - spanyol" },
	{ value: "es_PY", label:"Paraguay - spanyol" },
	{ value: "es_PE", label:"Peru - spanyol" },
	{ value: "en_PH", label:"Fülöp-szigetek - angol" },
	{ value: "pl_PL", label:"Lengyelország - lengyel" },
	{ value: "pt_PT", label:"Portugália - portugál" },
	{ value: "es_PR", label:"Puerto Rico - spanyol" },
	{ value: "en_RO", label:"Románia - angol" },
	{ value: "ru_RU", label:"Orosz Föderáció - orosz" },
	{ value: "en_SA", label:"Szaúd-Arábia - angol" },
	{ value: "en_SG", label:"Szingapúr - angol" },
	{ value: "en_LK", label:"Srí Lanka - angol" },
	{ value: "en_ZA", label:"Dél-Afrika - angol" },
	{ value: "en_SI", label:"Szlovénia - angol" },
	{ value: "sv_SE", label:"Svédország - svéd" },
	{ value: "fr_CH", label:"Svájc - francia" },
	{ value: "de_CH", label:"Svájc - német" },
	{ value: "zh_TW", label:"Tajvan - hagyományos kínai" },
	{ value: "en_TH", label:"Thaiföld - angol" },
	{ value: "th_TH", label:"Thaiföld - Thai" },
	{ value: "en_TT", label:"Trinidad és Tobago - angol" },
	{ value: "tr_TR", label:"Törökország - török" },
	{ value: "en_AE", label:"Egyesült Arab Emírségek" },
	{ value: "en_GB", label:"Egyesült Királyság - angol" },
	{ value: "es_UY", label:"Uruguay - spanyol" },
	{ value: "es_VE", label:"Venezuela - spanyol" },
	{ value: "en_VN", label:"Vietnam - angol" },
    { value: "en_SK", label: "Szlovákia - angol"},
    { value: "en_LT", label: "Litvánia - angol"},
    { value: "en_LV", label: "Lettország - angol"},
    { value: "en_EE", label: "Észtország - angol"},

]

export const primaryUse = [
  { value: "", label:"Válasszon egyet" },
	{ value: "002", label:"Személyes felhasználás" },
	{ value: "003", label:"Otthoni vállalkozás" },
	{ value: "005", label:"9 vagy kevesebb alkalmazottat foglalkoztató vállalkozás" },
	{ value: "006", label:"10 vagy több alkalmazottat foglalkoztató vállalkozás" },
]

export const salutation = [
  { value: "", label:"Válasszon egyet" },
	{ value: "001", label:"Úr" },
	{ value: "002", label:"Asszony" },
	{ value: "005", label:"Hölgy" },
]

export const jobResponsibility = [
  { value: "", label:"Válasszon ki egyet" },
	{ value: "041", label:"Adminisztrációs asszisztens" },
	{ value: "030", label:"Üzleti szakember" },
	{ value: "035", label:"CAD/CAM szakember" },
	{ value: "033", label:"Cégtulajdonos/elnök-vezérigazgató" },
	{ value: "055", label:"Tanácsadó/rendszerintegrátor" },
	{ value: "034", label:"Osztályvezető" },
	{ value: "046", label:"Mérnök, műszaki" },
	{ value: "054", label:"Ügyvezető/bizottsági tag" },
	{ value: "036", label:"Létesítményigazgató" },
	{ value: "047", label:"Pénzügyi, számviteli szakember" },
	{ value: "031", label:"Pénzügyi igazgató (CFO)" },
	{ value: "037", label:"Grafikai szakember" },
	{ value: "032", label:"Informatikai vezető (CIO/CTO)" },
	{ value: "051", label:"Informatikai stratéga" },
	{ value: "049", label:"Marketingszakember" },
	{ value: "045", label:"VIR, számítástechnikai csoport v. osztály vezetője" },
	{ value: "044", label:"VIR, informatikai szakember" },
	{ value: "042", label:"Irodavezető" },
	{ value: "043", label:"Beszerzés/üzleti vásárlás" },
	{ value: "050", label:"Értékesítési szakember" },
	{ value: "040", label:"Szolgáltatás, támogatás, ügyfélszolgálat" },
	{ value: "048", label:"Szoftverfejlesztő" },
	{ value: "052", label:"Igazgató" },
	{ value: "053", label:"Tanár" },
	{ value: "000", label:"Egyéb" },
]
