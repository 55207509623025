export const country_region = [
	{ value: "es_ES", label: "Spagna - Spagnolo" },
	{ value: "en_US", label: "Stati Uniti - Inglese" },
  { value: "es_AR", label: "Argentina - Spagnolo" },
	{ value: "en_AW", label: "Aruba - Inglese" },
	{ value: "en_AU", label: "Australia - Inglese" },
	{ value: "de_AT", label: "Austria - Tedesco" },
	{ value: "en_BS", label: "Bahamas - Inglese" },
	{ value: "en_BD", label: "Bangladesh - Inglese" },
	{ value: "en_BB", label: "Barbados - Inglese" },
	{ value: "nl_BE", label: "Belgio - Olandese" },
	{ value: "fr_BE", label: "Belgio - Francese" },
	{ value: "es_BO", label: "Bolivia - Spagnolo" },
	{ value: "pt_BR", label: "Brasile - Portoghese" },
	{ value: "en_BG", label: "Bulgaria - Inglese" },
	{ value: "en_CA", label: "Canada - Inglese" },
	{ value: "fr_CA", label: "Canada - Francese" },
	{ value: "es_CL", label: "Cile - Spagnolo" },
	{ value: "zh_CN", label: "Cina - Cinese semplificato" },
	{ value: "es_CO", label: "Colombia - Spagnolo" },
	{ value: "es_CR", label: "Costa Rica - Spagnolo" },
	{ value: "cs_CZ", label: "Repubblica Ceca - Ceco" },
	{ value: "da_DK", label: "Danimarca - Danese" },
	{ value: "es_DO", label: "Repubblica Dominicana - Spagnolo" },
	{ value: "es_EC", label: "Ecuador - Spagnolo" },
	{ value: "en_EG", label: "Egitto - Inglese" },
	{ value: "es_SV", label: "El Salvador - Spagnolo" },
	{ value: "fi_FI", label: "Finlandia - Finlandese" },
	{ value: "fr_FR", label: "Francia - Francese" },
	{ value: "de_DE", label: "Germania - Tedesco" },
	{ value: "el_GR", label: "Grecia - Greco" },
	{ value: "en_GD", label: "Grenada - Inglese" },
	{ value: "es_GT", label: "Guatemala - Spagnolo" },
	{ value: "es_HN", label: "Honduras - Spagnolo" },
	{ value: "en_HK", label: "Hong Kong - Inglese" },
	{ value: "zh_HK", label: "Hong Kong - Cinese tradizionale" },
	{ value: "hu_HU", label: "Ungheria - Ungherese" },
	{ value: "en_IN", label: "India - Inglese" },
	{ value: "en_ID", label: "Indonesia - Inglese" },
	{ value: "in_ID", label: "Indonesia - Indonesiano" },
	{ value: "en_IE", label: "Irlanda - Inglese" },
	{ value: "en_IL", label: "Israele - Inglese" },
	{ value: "it_IT", label: "Italia - Italiano" },
	{ value: "en_JM", label: "Giamaica - Inglese" },
	{ value: "ja_JP", label: "Giappone - Giapponese" },
	{ value: "ko_KR", label: "Corea - Coreano" },
	{ value: "en_KW", label: "Kuwait - Inglese" },
	{ value: "en_LB", label: "Libano - inglese" },
	{ value: "fr_LU", label: "Lussemburgo - Francese" },
	{ value: "en_MY", label: "Malaysia - Inglese" },
	{ value: "es_MX", label: "Messico - Spagnolo" },
	{ value: "en_MA", label: "Marocco - Inglese" },
	{ value: "nl_NL", label: "Paesi Bassi - Olandese" },
	{ value: "en_NZ", label: "Nuova Zelanda - Inglese" },
	{ value: "es_NI", label: "Nicaragua - Spagnolo" },
	{ value: "no_NO", label: "Norvegia - Norvegese" },
	{ value: "en_PK", label: "Pakistan - Inglese" },
	{ value: "es_PA", label: "Panama - Spagnolo" },
	{ value: "es_PY", label: "Paraguay - Spagnolo" },
	{ value: "es_PE", label: "Perù - Spagnolo" },
	{ value: "en_PH", label: "Filippine - Inglese" },
	{ value: "pl_PL", label: "Polonia - Polacco" },
	{ value: "pt_PT", label: "Portogallo - Portoghese" },
	{ value: "es_PR", label: "Porto Rico - Spagnolo" },
	{ value: "en_RO", label: "Romania - Inglese" },
	{ value: "ru_RU", label: "Russia - Russo" },
	{ value: "en_SA", label: "Arabia Saudita - Inglese" },
	{ value: "en_SG", label: "Singapore - Inglese" },
	{ value: "en_LK", label: "Sri Lanka - Inglese" },
	{ value: "en_ZA", label: "Sud Africa - Inglese" },
	{ value: "en_SI", label: "Slovenia - Inglese" },
	{ value: "sv_SE", label: "Svezia - Svedese" },
	{ value: "fr_CH", label: "Svizzera - Francese" },
	{ value: "de_CH", label: "Svizzera - Tedesco" },
	{ value: "zh_TW", label: "Taiwan - Cinese tradizionale" },
	{ value: "en_TH", label: "Thailandia - Inglese" },
	{ value: "th_TH", label: "Thailandia - Thai" },
	{ value: "en_TT", label: "Trinidad e Tobago - Inglese" },
	{ value: "tr_TR", label: "Turchia - Turco" },
	{ value: "en_AE", label: "Emirati Arabi Uniti - Inglese" },
	{ value: "en_GB", label: "Regno Unito - Inglese" },
	{ value: "es_UY", label: "Uruguay - Spagnolo" },
	{ value: "es_VE", label: "Venezuela - Spagnolo" },
	{ value: "en_VN", label: "Vietnam - Inglese" },
    { value: "en_SK", label: "Slovacchia - Inglese"},
    { value: "en_LT", label: "Lituania - Inglese"},
    { value: "en_LV", label: "Lettonia - Inglese"},
    { value: "en_EE", label: "Estonia - Inglese"},


]

export const primaryUse = [
  { value: "", label: "Effettua selezione" },
	{ value: "002", label: "Uso personale" },
	{ value: "003", label: "Lavoro da casa" },
	{ value: "005", label: "Società con 9 o meno dipendenti" },
	{ value: "006", label: "Società con 10 o più dipendenti" },
]

export const salutation = [
  { value: "", label: "Effettua selezione" },
	{ value: "001", label: "Signor" },
	{ value: "003", label: "Signora" },
]

export const jobResponsibility = [
  { value: "", label: "Selezionare una voce" },
	{ value: "041", label: "Assistente amministrativo" },
	{ value: "030", label: "Responsabile d'impresa" },
	{ value: "035", label: "Operatore CAD/CAM" },
	{ value: "033", label: "Proprietario dell'impresa/CEO" },
	{ value: "055", label: "Consulente/Integratore di sistema" },
	{ value: "034", label: "Capo reparto" },
	{ value: "046", label: "Ingegnere/tecnico" },
	{ value: "054", label: "Dirigente/Membro del consiglio" },
	{ value: "036", label: "Responsabile della struttura" },
	{ value: "047", label: "Quadri finanziari/contabili" },
	{ value: "031", label: "Agente/Direttore Finanziario" },
	{ value: "037", label: "Grafico" },
	{ value: "032", label: "Dirigente IT (CIO/CTO)" },
	{ value: "051", label: "Responsabile delle strategie IT" },
	{ value: "049", label: "Responsabile del marketing" },
	{ value: "045", label: "Capo reparto/gruppo MIS/IT" },
	{ value: "044", label: "Professionista informatico" },
	{ value: "042", label: "Office Manager" },
	{ value: "043", label: "Addetto agli acquisti/all'approvvigionamento" },
	{ value: "050", label: "Responsabile vendite" },
	{ value: "040", label: "Servizio/Supporto/Help Desk" },
	{ value: "048", label: "Sviluppatore software" },
	{ value: "052", label: "Sovrintendente/direttore" },
	{ value: "053", label: "Docente" },
	{ value: "000", label: "Altro" },
]
