export const salutation = [
    {value:"001", label:"Sr"},
	{value:"002", label:"Srta."},
	{value:"005", label:"Sra."}
];

export const jobResponsibility = [
    {value: "", label:"Seleccione uno"},
	{value: "041", label:"Auxiliar administrativo"},
	{value: "030", label:"Profesional empresarial"},
	{value: "035", label:"Profesional de CAD/CAM"},
	{value: "033", label:"Propietario de la empresa/Director general"},
	{value: "055", label:"Asesor/integrador de sistemas"},
	{value: "034", label:"Jefe de departamento"},
	{value: "046", label:"Ingeniero/técnico"},
	{value: "054", label:"Ejecutivo/miembro del Consejo de administración"},
	{value: "036", label:"Responsable de instalaciones"},
	{value: "047", label:"Profesional financiero/contabilidad"},
	{value: "031", label:"Agente/director financiero"},
	{value: "037", label:"Profesional de artes gráficas"},
	{value: "032", label:"Ejecutivo de IT (Director de Información/Director Técnico)"},
	{value: "051", label:"Responsable de estrategia de IT"},
	{value: "049", label:"Profesional de marketing"},
	{value: "045", label:"Administrador de departamento o grupo de sistemas de información para la gestión/IT"},
	{value: "044", label:"Profesional de MIS/IT"},
	{value: "042", label:"Responsable de oficina"},
	{value: "043", label:"Gerente de stock/departamento de compras"},
	{value: "050", label:"Profesional de ventas"},
	{value: "040", label:"Servicio/Asistencia/Centro de atención"},
	{value: "048", label:"Desarrollador de software"},
	{value: "052", label:"Superintendente/director"},
	{value: "053", label:"Profesor"},
	{value: "000", label:"Otros"},
]

export const country_region = [
	{value: "es_ES", label: "España - Español"},
	{value: "en_US", label: "Estados Unidos - Inglés"},
	{value: "es_AR", label:"Argentina - Español"},
	{value: "en_AW", label:"Aruba - Inglés"},
	{value: "en_AU", label:"Australia - Inglés"},
	{value: "de_AT", label:"Austria - Alemán"},
	{value: "en_BS", label:"Bahamas - Inglés "},
	{value: "en_BD", label:"Bangladesh - Inglés"},
	{value: "en_BB", label:"Barbados - Inglés"},
	{value: "nl_BE", label:"Bélgica - Neerlandés"},
	{value: "fr_BE", label:"Bélgica - Francés"},
	{value: "es_BO", label:"Bolivia - Español"},
	{value: "pt_BR", label:"Brasil - Portugués"},
	{value: "en_BG", label:"Bulgaria - Inglés"},
	{value: "en_CA", label:"Canadá - Inglés"},
	{value: "fr_CA", label:"Canadá - Francés"},
	{value: "es_CL", label:"Chile - Español"},
	{value: "zh_CN", label:"China - Chino simplificado"},
	{value: "es_CO", label:"Colombia - Español"},
	{value: "es_CR", label:"Costa Rica - Español"},
	{value: "cs_CZ", label:"República Checa - checo"},
	{value: "da_DK", label:"Dinamarca - Danés"},
	{value: "es_DO", label:"República Dominicana - Español"},
	{value: "es_EC", label:"Ecuador - Español"},
	{value: "en_EG", label:"Egipto - Inglés"},
	{value: "es_SV", label:"El Salvador - Español"},
	{value: "fi_FI", label:"Finlandia - finlandés"},
	{value: "fr_FR", label:"Francia - Francés"},
	{value: "de_DE", label:"Alemania - Alemán"},
	{value: "el_GR", label:"Grecia - griego"},
	{value: "en_GD", label:"Granada - Inglés"},
	{value: "es_GT", label:"Guatemala - Español"},
	{value: "es_HN", label:"Honduras - Español"},
	{value: "en_HK", label:"Hong Kong - Inglés"},
	{value: "zh_HK", label:"Hong Kong - Chino tradicional"},
	{value: "hu_HU", label:"Hungría - húngaro"},
	{value: "en_IN", label:"India - Inglés"},
	{value: "en_ID", label:"Indonesia - Inglés"},
	{value: "in_ID", label:"Indonesia - Indonesia"},
	{value: "en_IE", label:"Irlanda - Inglés"},
	{value: "en_IL", label:"Israel - Inglés"},
	{value: "it_IT", label:"Italia - Italiano"},
	{value: "en_JM", label:"Jamaica - Inglés"},
	{value: "ja_JP", label:"Japón - Japonés"},
	{value: "ko_KR", label:"Corea - Coreano"},
	{value: "en_KW", label:"Kuwait - Inglés"},
	{value: "en_LB", label:"Líbano - Inglés"},
	{value: "fr_LU", label:"Luxemburgo - Francés"},
	{value: "en_MY", label:"Malasia - Inglés"},
	{value: "es_MX", label:"México - Español"},
	{value: "en_MA", label:"Marruecos - Inglés"},
	{value: "nl_NL", label:"Países Bajos - Neerlandés"},
	{value: "en_NZ", label:"Nueva Zelanda - Inglés"},
	{value: "es_NI", label:"Nicaragua - Español"},
	{value: "no_NO", label:"Noruega - Noruego"},
	{value: "en_PK", label:"Pakistán - Inglés"},
	{value: "es_PA", label:"Panamá - Español"},
	{value: "es_PY", label:"Paraguay - Español"},
	{value: "es_PE", label:"Perú - Español"},
	{value: "en_PH", label:"Filipinas - Inglés"},
	{value: "pl_PL", label:"Polonia - Polaco"},
	{value: "pt_PT", label:"Portugal - Portugués"},
	{value: "es_PR", label:"Puerto Rico - Español"},
	{value: "en_RO", label: "Rumania - Inglés"},
	{value: "ru_RU", label: "Federación Rusa - Ruso"},
	{value: "en_SA", label: "Arabia Saudí - Inglés"},
	{value: "en_SG", label: "Singapur - Inglés"},
	{value: "en_LK", label: "Sri Lanka - Inglés"},
	{value: "en_ZA", label: "Sudáfrica - Inglés"},
	{value: "en_SI", label: "Eslovenia - Inglés"},
	{value: "sv_SE", label: "Suecia - Sueco"},
	{value: "fr_CH", label: "Suiza - Francés"},
	{value: "de_CH", label: "Suiza - Alemán"},
	{value: "zh_TW", label: "Taiwán - Chino tradicional"},
	{value: "en_TH", label: "Tailandia - Inglés"},
	{value: "th_TH", label: "Tailandia - Tailandia"},
	{value: "en_TT", label: "Trinidad y Tobago - Inglés "},
	{value: "tr_TR", label: "Turquía - Turco"},
	{value: "en_AE", label: "Emiratos Árabes Unidos - Inglés"},
	{value: "en_GB", label: "Reino Unido - Inglés"},
	{value: "es_UY", label: "Uruguay - Español"},
	{value: "es_VE", label: "Venezuela - Español"},
	{value: "en_VN", label: "Vietnam - Inglés"},
    { value: "en_SK", label: "Eslovaquia - Inglés"},
    { value: "en_LT", label: "Lituania - Inglés"},
    { value: "en_LV", label: "Letonia - Inglés"},
    { value: "en_EE", label: "Estonia - Inglés"},
];

export const primaryUse = [
	{value: "002", label: "Uso personal"},
	{value: "003", label: "Empresa en el hogar"},
	{value: "005", label: "Empresa con 9 o menos empleados"},
	{value: "006", label: "Empresa con 10 o más empleados"}
];
