import data from './lang.json';
import objects from '../../tools/objects';
import captchaLangs from '../../config/constants/captchaLangs.json';

import moment from 'moment';

import 'moment/locale/zh-cn';
import 'moment/locale/zh-hk';
import 'moment/locale/cs';
import 'moment/locale/de';
import 'moment/locale/da';
import 'moment/locale/es';
import 'moment/locale/fi';
import 'moment/locale/fr';
import 'moment/locale/el';
import 'moment/locale/hu';
import 'moment/locale/it';
import 'moment/locale/ja';
import 'moment/locale/ko';
import 'moment/locale/nl';
import 'moment/locale/pl';
import 'moment/locale/pt';
import 'moment/locale/ru';
import 'moment/locale/sv';
import 'moment/locale/tr';

let selData = {};

const getMonths = (lang = 'en') => {
  lang = sanitizeLocaleLang(lang);
  moment.locale(lang);
  return moment.months();
};

// Special case for locale langs (only chinese now)
const sanitizeLocaleLang = (lang) => {
  if (['zh'].includes(lang)) {
    const [locale, _] = selData.language.split('-');
    lang =  `${lang}-${locale}`;
  }
  return lang;
}

const geti18nText = (key) => {
  return selData[key] || '';
}

const geti18nLng = () => {
  if (!objects.isObjectWithData(selData)) {
    return 'en';
  }

  const [,lng] = selData.language.split('-');

  return lng ? lng.toLowerCase() : 'en';
}

const geti18nCC = () => {
  if (!objects.isObjectWithData(selData)) {
    return 'US';
  }

  const [cc] = selData.language.split('-');

  return cc ? cc.toUpperCase() : 'US';
}

const checkLang = (params) => {
  console.log(data)
  let result = null;

  const key_A = `${params.cc}-${params.lang}`.toUpperCase();
  const key_B = `${params.lang}-${params.lang}`.toUpperCase();

  result = data.find((item) => item.language === key_A || item.language === key_B);
  selData = result ? result : data.find((item) => item.language === 'US-EN');

  return result ? true : false;
}

const getCaptchaLang = () => {
  const lang = sanitizeLocaleLang(geti18nLng());
  if (captchaLangs.validLangs.includes(lang)) {
    return lang;
  }
  return 'en';
}

export {
  checkLang,
  getCaptchaLang,
  geti18nText,
  geti18nLng,
  geti18nCC,
  getMonths
};
