export const country_region = [
	{ value: "es_ES", label: "İspanya - İspanyolca" },
	{ value: "en_US", label: "Amerika Birleşik Devletleri - İngilizce" },
    { value: "", label: "Birini Seçin" },
    { value: "es_AR", label: "Arjantin - İspanyolca" },
	{ value: "en_AW", label: "Aruba - İngilizce" },
	{ value: "en_AU", label: "Avustralya - İngilizce" },
	{ value: "de_AT", label: "Almanya - Almanca" },
	{ value: "en_BS", label: "Bahamalar - İngilizce" },
	{ value: "en_BD", label: "Bangladeş - İngilizce" },
	{ value: "en_BB", label: "Barbados - İngilizce" },
	{ value: "nl_BE", label: "Belçika - Flamanca" },
	{ value: "fr_BE", label: "Belçika - Fransızca" },
	{ value: "es_BO", label: "Bolivya - İspanyolca" },
	{ value: "pt_BR", label: "Brezilya - Portekizce" },
	{ value: "en_BG", label: "Bulgaristan - İngilizce" },
    { value: "en_CA", label: "Kanada - İngilizce" },
	{ value: "fr_CA", label: "Kanada - Fransızca" },
	{ value: "es_CL", label: "Şili - İspanyolca" },
	{ value: "zh_CN", label: "Çin - Basitleştirilmiş Çince" },
	{ value: "es_CO", label: "Kolombiya - İspanyolca" },
	{ value: "es_CR", label: "Kosta Rika - İspanyolca" },
	{ value: "cs_CZ", label: "Çek Cumhuriyeti - Çekçe" },
	{ value: "da_DK", label: "Danimarka - Danca" },
	{ value: "es_DO", label: "Dominik Cumhuriyeti - İspanyolca" },
    { value: "es_EC", label: "Ekvator - İspanyolca" },
	{ value: "en_EG", label: "Kanada - İngilizce" },
	{ value: "es_SV", label: "El Salvador - İspanyolca" },
	{ value: "fi_FI", label: "Finlandiya - Fince" },
	{ value: "fr_FR", label: "Fransa - Fransızca" },
	{ value: "de_DE", label: "Almanya - Almanca" },
	{ value: "el_GR", label: "Yunanistan - Yunanca" },
	{ value: "en_GD", label: "İrlanda - İngilizce" },
	{ value: "es_GT", label: "Şili - İspanyolca" },
    { value: "es_HN", label: "Honduras - İspanyolca" },
	{ value: "en_HK", label: "Hong Kong - İngilizce" },
	{ value: "zh_HK", label: "Hong Kong - Geleneksel Çince" },
	{ value: "hu_HU", label: "Macaristan - Macarca" },
	{ value: "en_IN", label: "Hindistan - İngilizce" },
	{ value: "en_ID", label: "Endonezya - İngilizce" },
	{ value: "in_ID", label: "Endonezya  -  Endonezya" },
	{ value: "en_IE", label: "İrlanda - İngilizce" },
	{ value: "en_IL", label: "Israel - İngilizce" },
	{ value: "it_IT", label: "İtalya - İtalyanca" },
    { value: "en_JM", label: "Jamaika - İngilizce" },
	{ value: "ja_JP", label: "Japonya - Japonca" },
	{ value: "ko_KR", label: "Kore - Kore dili" },
	{ value: "en_KW", label: "Kanada - İngilizce" },
	{ value: "en_LB", label: "Lübnan - İngilizce" },
	{ value: "fr_LU", label: "Lüksemburg - Fransızca" },
    { value: "en_MY", label: "Malezya - İngilizce" },
	{ value: "es_MX", label: "Meksika - İspanyolca" },
	{ value: "en_MA", label: "Fas - İngilizce" },
	{ value: "nl_NL", label: "Hollanda - Felemenkçe" },
	{ value: "en_NZ", label: "Yeni Zelanda - İngilizce" },
	{ value: "es_NI", label: "Nikaragua - İspanyolca" },
	{ value: "no_NO", label: "Norveç - Norveççe" },
    { value: "en_PK", label: "Pakistan - İngilizce" },
	{ value: "es_PA", label: "Panama - İspanyolca" },
	{ value: "es_PY", label: "Paraguay - İspanyolca" },
	{ value: "es_PE", label: "Peru - İspanyolca" },
	{ value: "en_PH", label: "Filipinler - İngilizce" },
	{ value: "pl_PL", label: "Polonya - Lehçe" },
	{ value: "pt_PT", label: "Brezilya - Portekizce" },
	{ value: "es_PR", label: "Porto Riko - İspanyolca" },
    { value: "en_RO", label: "Romanya - İngilizce" },
	{ value: "ru_RU", label: "Rusya Federasyonu - Rusça" },
	{ value: "en_SA", label: "Suudi Arabistan - İngilizce" },
	{ value: "en_SG", label: "Singapur - İngilizce" },
	{ value: "en_LK", label: "Sri Lanka - İngilizce" },
	{ value: "en_ZA", label: "Güney Afrika - İngilizce" },
	{ value: "en_SI", label: "Slovenya - İngilizce" },
	{ value: "sv_SE", label: "İsveç - İsveççe" },
	{ value: "fr_CH", label: "İsviçre - Fransızca" },
	{ value: "de_CH", label: "İsviçre - Almanca" },
    { value: "zh_TW", label: "Tayvan - Geleneksel Çince" },
	{ value: "en_TH", label: "Tayland - İngilizce" },
	{ value: "th_TH", label: "Tayland  -  Tayland" },
	{ value: "en_TT", label: "Trinidad ve Tobago - İngilizce" },
	{ value: "tr_TR", label: "Türkiye - Türkçe" },
    { value: "en_AE", label: "Birleşik Arap Emirlikleri - İngilizce" },
	{ value: "en_GB", label: "İngiltere - İngilizce" },
	{ value: "es_UY", label: "Uruguay - İspanyolca" },
	{ value: "es_VE", label: "Venezuela - İspanyolca" },
	{ value: "en_VN", label: "Vietnam - İngilizce" },
    { value: "en_SK", label: "Slovakya - İngilizce"},
    { value: "en_LT", label: "Litvanya - İngilizce"},
    { value: "en_LV", label: "Letonya - İngilizce"},
    { value: "en_EE", label: "Estonya - İngilizce"},

]

export const primaryUse = [
  { value: "", label: "Birini Seçin" },
	{ value: "002", label: "Kişisel Kullanım" },
	{ value: "003", label: "Ev İşyeri" },
	{ value: "005", label: "9 veya daha az çalışanlı işyeri" },
	{ value: "006", label: "10 veya daha fazla çalışanlı işyeri" },
]

export const salutation = [
    { value: "", label: "Birini Seçin" },
	{ value: "001", label: "Bay" },
	{ value: "003", label: "Bayan" },
]

export const jobResponsibility = [
    { value: "", label: "Birini Seçin" },
	{ value: "041", label: "İdari Yardımcı" },
	{ value: "030", label: "Profesyonel Çalışan" },
	{ value: "035", label: "CAD/CAM Uzmanı" },
	{ value: "033", label: "Şirket Sahibi/İB" },
	{ value: "055", label: "Danışman/Sistem Entegrasyonu Yetkilisi" },
	{ value: "034", label: "Departman Başkanı" },
	{ value: "046", label: "Mühendis/Teknisyen" },
	{ value: "054", label: "Yönetici/Yönetim Kurulu Üyesi" },
	{ value: "036", label: "Hizmet Yöneticisi" },
	{ value: "047", label: "Finansman/Muhasebe Uzmanı" },
	{ value: "031", label: "Finansman Yetkilisi/Finansman Başkanı" },
	{ value: "037", label: "Grafik Sanatçısı" },
	{ value: "032", label: "BT Yöneticisi (BB/TB)" },
	{ value: "051", label: "BT Stratejisti" },
	{ value: "049", label: "Pazarlama Uzmanı" },
	{ value: "045", label: "YBS/BT Grubu/Departmanı Yöneticisi" },
	{ value: "044", label: "YBS/BT Uzmanı" },
	{ value: "042", label: "Ofis Yöneticisi" },
	{ value: "043", label: "Satın Alma/Tedarik Uzmanı" },
	{ value: "050", label: "Satış Uzmanı" },
	{ value: "040", label: "Hizmet/Destek/Yardım Masası" },
	{ value: "048", label: "Yazılım Geliştirici" },
	{ value: "052", label: "Müfettiş/Baş Öğretmen" },
	{ value: "053", label: "Öğretmen" },
	{ value: "000", label: "Diğer" },
]
