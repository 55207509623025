export const country_region = [
	{ value: "es_ES", label: "スペイン - スペイン語" },
	{ value: "en_US", label: "アメリカ合衆国 - 英語" },
    { value: "en_IE", label: "アイルランド - 英語" },
	{ value: "en_AA", label: "アフリカ - 英語" },
	{ value: "fr_AA", label: "アフリカ - フランス語" },
	{ value: "en_AE", label: "アラブ首長国連邦 - 英語" },
	{ value: "es_AR", label: "アルゼンチン - スペイン語" },
	{ value: "en_GB", label: "イギリス - 英語" },
	{ value: "he_IL", label: "イスラエル - ヘブライ語" },
	{ value: "it_IT", label: "イタリア - イタリア語" },
	{ value: "en_IN", label: "インド - 英語" },
	{ value: "en_ID", label: "インドネシア - 英語" },
	{ value: "in_ID", label: "インドネシア - インドネシア語" },
	{ value: "uk_UA", label: "ウクライナ - ロシア語" },
	{ value: "es_UY", label: "ウルグアイ - スペイン語" },
	{ value: "es_EC", label: "エクアドル - スペイン語" },
	{ value: "et_EE", label: "エストニア - エストニア語" },
	{ value: "en_AU", label: "オーストラリア - 英語" },
	{ value: "de_AT", label: "オーストリー - ドイツ語" },
	{ value: "nl_NL", label: "オランダ - オランダ語" },
	{ value: "ru_KZ", label: "カザフスタン - ロシア語" },
	{ value: "en_CA", label: "カナダ - 英語" },
	{ value: "fr_CA", label: "カナダ - フランス語" },
	{ value: "en_CB", label: "カリブ諸島 - 英語" },
	{ value: "ko_KR", label: "韓国 - 韓国語" },
	{ value: "el_GR", label: "ギリシャ - ギリシャ語" },
	{ value: "hr_HR", label: "クロアチア - クロアチア語" },
	{ value: "es_CO", label: "コロンビア - スペイン語" },
	{ value: "en_SA", label: "サウジアラビア - 英語" },
	{ value: "en_SG", label: "シンガポール - 英語" },
	{ value: "fr_CH", label: "スイス - フランス語" },
	{ value: "de_CH", label: "スイス - ドイツ語" },
	{ value: "sv_SE", label: "スウェーデン - スウェーデン語" },
	{ value: "en_LK", label: "スリランカ - 英語" },
	{ value: "sk_SK", label: "スロバキア - スロバキア語" },
	{ value: "en_SI", label: "スロベニア - 英語" },
	{ value: "sr_YU", label: "モンテネグロ - セルビア語" },
	{ value: "en_TH", label: "タイ - 英語" },
	{ value: "th_TH", label: "タイ - タイ語" },
	{ value: "zh_TW", label: "台湾 - 繁体字中国語" },
	{ value: "cs_CZ", label: "チェコ共和国 - チェコ語" },
	{ value: "es_NS", label: "中央アメリカ - スペイン語" },
	{ value: "zh_CN", label: "中国 - 簡体字中国語" },
	{ value: "en_ME", label: "中東 - 英語" },
	{ value: "es_CL", label: "チリ - スペイン語" },
	{ value: "da_DK", label: "デンマーク - デンマーク語" },
	{ value: "de_DE", label: "ドイツ - ドイツ語" },
	{ value: "tr_TR", label: "トルコ - トルコ語" },
	{ value: "ja_JP", label: "日本 - 日本語" },
	{ value: "en_LB", label: "レバノン - 英語" },
	{ value: "en_NZ", label: "ニュージーランド - 英語" },
	{ value: "no_NO", label: "ノルウェー - ノルウェー語" },
	{ value: "en_BD", label: "バングラデシュ - 英語" },
	{ value: "en_PK", label: "パキスタン - 英語" },
	{ value: "es_PY", label: "パラグアイ - スペイン語" },
	{ value: "hu_HU", label: "ハンガリー - ハンガリー語" },
	{ value: "en_PH", label: "フィリピン - 英語" },
	{ value: "fi_FI", label: "フィンランド - フィンランド語" },
	{ value: "es_PR", label: "プエルトリコ - スペイン語" },
	{ value: "fr_FR", label: "フランス - フランス語" },
	{ value: "pt_BR", label: "ブラジル - ポルトガル語" },
	{ value: "bg_BG", label: "ブルガリア - ブルガリア語" },
	{ value: "en_BG", label: "ブルガリア - 英語" },
	{ value: "en_VN", label: "ベトナム - 英語" },
	{ value: "es_VE", label: "ベネズエラ - スペイン語" },
	{ value: "be_BY", label: "ベラルーシ - ロシア語" },
	{ value: "es_PE", label: "ペルー - スペイン語" },
	{ value: "nl_BE", label: "ベルギー - オランダ語" },
	{ value: "fr_BE", label: "ルクセンブルク - フランス語" },
	{ value: "pl_PL", label: "ポーランド - ポーランド語" },
	{ value: "pt_PT", label: "ポルトガル - ポルトガル語" },
	{ value: "es_BO", label: "ボリビア - スペイン語" },
	{ value: "en_HK", label: "香港 - 英語" },
	{ value: "zh_HK", label: "香港 - 繁体字中国語" },
	{ value: "en_MY", label: "マレーシア - 英語" },
	{ value: "en_ZA", label: "南アフリカ - 英語" },
	{ value: "es_MX", label: "メキシコ - スペイン語" },
	{ value: "lv_LV", label: "ラトビア - ラトビア語" },
	{ value: "lt_LT", label: "リトアニア - リトアニア語" },
	{ value: "en_RO", label: "ルーマニア - 英語" },
	{ value: "ru_RU", label: "ロシア - ロシア語" },
	{ value: "all", label: "その他の国または地域" },
    { value: "en_SK", label: "スロバキア - 英語"},
    { value: "en_LT", label: "リトアニア - 英語"},
    { value: "en_LV", label: "ラトビア - 英語"},
    { value: "en_EE", label: "エストニア - 英語"},

]

export const pur_loc_4 = [
{ value: "", label: "選択してください" },
	{ value: "001", label: "小売店（家電量販店など）" },
	{ value: "003", label: "ＯＡディーラー" },
	{ value: "005", label: "HP直販営業" },
	{ value: "006", label: "HPオンラインストア（HP directplus)" },
	{ value: "002", label: "その他のオンラインストア" },
	{ value: "007", label: "通信販売" },
	{ value: "008", label: "その他" },
]

export const primaryUse = [
  { value: "", label: "選択してください" },
	{ value: "002", label: "個人ユーザ" },
	{ value: "005", label: "法人ユーザ（社員数9名以下)" },
	{ value: "006", label: "法人ユーザ（社員数10名以上)" },
]

export const busState = [
{ value: "", label: "(選択してください)" },
	{ value: "北海道", label: "北海道" },
	{ value: "青森県", label: "青森県" },
	{ value: "岩手県", label: "岩手県" },
	{ value: "秋田県", label: "秋田県" },
	{ value: "宮城県", label: "宮城県" },
	{ value: "山形県", label: "山形県" },
	{ value: "福島県", label: "福島県" },
	{ value: "東京都", label: "東京都" },
	{ value: "神奈川県", label: "神奈川県" },
	{ value: "千葉県", label: "千葉県" },
	{ value: "埼玉県", label: "埼玉県" },
	{ value: "茨城県", label: "茨城県" },
	{ value: "栃木県", label: "栃木県" },
	{ value: "群馬県", label: "群馬県" },
	{ value: "山梨県", label: "山梨県" },
	{ value: "長野県", label: "長野県" },
	{ value: "新潟県", label: "新潟県" },
	{ value: "富山県", label: "富山県" },
	{ value: "石川県", label: "石川県" },
	{ value: "福井県", label: "福井県" },
	{ value: "愛知県", label: "愛知県" },
	{ value: "静岡県", label: "静岡県" },
	{ value: "三重県", label: "三重県" },
	{ value: "岐阜県", label: "岐阜県" },
	{ value: "大阪府", label: "大阪府" },
	{ value: "京都府", label: "京都府" },
	{ value: "兵庫県", label: "兵庫県" },
	{ value: "和歌山県", label: "和歌山県" },
	{ value: "奈良県", label: "奈良県" },
	{ value: "滋賀県", label: "滋賀県" },
	{ value: "広島県", label: "広島県" },
	{ value: "岡山県", label: "岡山県" },
	{ value: "鳥取県", label: "鳥取県" },
	{ value: "島根県", label: "島根県" },
	{ value: "山口県", label: "山口県" },
	{ value: "愛媛県", label: "愛媛県" },
	{ value: "香川県", label: "香川県" },
	{ value: "高知県", label: "高知県" },
	{ value: "徳島県", label: "徳島県" },
	{ value: "福岡県", label: "福岡県" },
	{ value: "佐賀県", label: "佐賀県" },
	{ value: "長崎県", label: "長崎県" },
	{ value: "熊本県", label: "熊本県" },
	{ value: "大分県", label: "大分県" },
	{ value: "宮崎県", label: "宮崎県" },
	{ value: "鹿児島県", label: "鹿児島県" },
	{ value: "沖縄県", label: "沖縄県" },
	{ value: "海外", label: "海外" },
]

export const salutation = [
	{ value: "", label:"Select One" },
	{ value: "001", label:"Mr." },
	{ value: "002", label:"Mrs." },
	{ value: "005", label:"Miss" },
]

export const jobResponsibility = [
  { value: "", label: "(選択してください)" },
	{ value: "030", label: "ビジネス専門家" },
	{ value: "031", label: "財務責任者" },
	{ value: "032", label: "情報/技術責任者" },
	{ value: "033", label: "経営責任者" },
	{ value: "034", label: "部門長" },
	{ value: "036", label: "設計運営管理責任者" },
	{ value: "037", label: "デザイン" },
	{ value: "040", label: "サポート/ヘルプデスク" },
	{ value: "041", label: "アシスタント業務" },
	{ value: "042", label: "業務責任者" },
	{ value: "043", label: "資材/購買" },
	{ value: "044", label: "経営情報システム" },
	{ value: "045", label: "経営情報システム責任者" },
	{ value: "046", label: "エンジニア" },
	{ value: "047", label: "経理/財務" },
	{ value: "048", label: "ソフトウェア開発" },
	{ value: "049", label: "企画/マーケティング" },
	{ value: "050", label: "営業/販売" },
	{ value: "051", label: "ＩＴ/情報処理関係" },
	{ value: "052", label: "校長" },
	{ value: "053", label: "教師" },
	{ value: "054", label: "役員" },
	{ value: "055", label: "コンサルタント/システムインテグレーター" },
	{ value: "000", label: "その他" },
]
